<template>
  <PurchaseNoDeliveryComponent
    goods-type="S0402"
    title="사급 원자재 입고"
    :input-type="inputType"
    :purchase-type="purchaseType"
  />
</template>
<script>
import ConstDef from '../../../../MesSmartVue/src/share/ConstDef'
import PurchaseNoDeliveryComponent from './원자재_입고_컴포넌트.vue'
export default {
  name: 'PurchaseNoDelivery',
  components: {
    PurchaseNoDeliveryComponent
  },
  data: function () {
    return {
      isLoading: false,
      userInfo: null,
      purchaseType: ConstDef.사급,
      inputType: ConstDef.자재입고
    }
  },
  created () {

  },
  mounted () {
  },
  updated () {
  },
  methods: {
  }
}
</script>

<style lang="scss">

</style>
